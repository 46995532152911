<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Fiche template <strong>{{tpl.titre}}</strong></h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nom:">
                <b-form-input v-model="tpl.titre" type="text" required placeholder="Entre le titre"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <div class="form-group">
                <label>Statut:</label>
                <select class="form-control" v-model="tpl.status">
                  <option v-for="(s,i) in parametrage.status" :value="s.id" :key="i">{{s.titre}}</option>
                </select>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Role:">
                <b-form-select v-model="tpl.roles" :options="parametrage.role" multiple></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Type:">
                <select class="form-control" v-model="tpl.type">
                  <option value="lead">Lead</option>
                  <option value="formation">Formation</option>
                </select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Objets:">
            <model-select :options="objs" v-model="obj" placeholder="select item"></model-select>
          </b-form-group>
          <b-row>
            <b-col md="4" offset-md="4">
              <a class="btn btn-block btn-primary mr-1" @click="addAttribues()">
                <i class="icon-md fas fa-arrow-down fa-lg"></i>
              </a>
            </b-col>
          </b-row>
          <b-form-group label="Text:">
            <quill-editor v-model="tpl.html" rows="9"></quill-editor>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ModelSelect } from 'vue-search-select'

export default {
  data(){
    return {
      htmlPreview:null,
      objs: [
      ],
      obj: {
        value: '',
        text: ''
      },
      tpl:{
        titre: null,
        html: null,
        roles: [],
        type: null,
        date_create: null,
        date_update: null,
        id: null,
        status:null
      },
      parametrage:{
        role:[],
        status:[]
      },
    };
  },
  watch: {
  },
  methods: {
    save() {
      this.$store.back.ajax('/template/create', this.tpl, (res) => {
        if(res.status === true){
          this.tpl = res.data;
          this.$router.push({path:`/parametrage/template/detail/${this.tpl.id}`});
        }
      });
    },
    addAttribues(){
      /*var curPos = this.$refs.html.quill.getSelection();
      if(curPos === null){
        return false;
      }
      curPos = curPos.index;
      console.log(curPos);*/
      //this.tpl.html = this.tpl.html.splice(curPos,0,this.obj.value)
      this.tpl.html += this.obj.value;
    },
  },
  components: {
    ModelSelect
  },
  computed: {

  },
  beforeMount(){
    let params = {
      modules: ["UserRole"]
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.status = res.data['status'];
        this.parametrage.role = res.data['UserRole'];
        this.parametrage.role.map((r) => {r.value = r.id});
        this.parametrage.role.map((r) => {r.text = r.titre});
      }
    });
    this.$store.back.ajax('/template/objets', null, (res) => {
      if(res.status === true){
        this.objs = res.data
      }
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.$store.back.ajax(`/template/${this.$route.params.id}`, null, (res) => {
      if(res.status === true){
        this.tpl = res.data;
      }
    });
  }
};
</script>
